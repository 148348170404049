@import "bootstrap/dist/css/bootstrap.css";
@import 'font-awesome/css/font-awesome.css';

.space-elements>*:not(:last-child) {
    margin-right: 0.25rem;
}

.clickable {
    cursor: pointer;
}

body {
    background: url(/assets/background.png) repeat;
    background-color: #f0fafc;
}

.screenshot-contain {
    object-fit: contain;
    width: 100%;
    max-width: 320px;
}

